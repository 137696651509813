import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useUser } from '../UserContext';

const MemeHistory: React.FC = () => {
  const { user } = useUser();

  return (
    <div className="min-h-screen bg-ai-background text-ai-text p-8">
      <div className="max-w-4xl mx-auto meme-history-container rounded-lg p-8">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <img src="/logo.png" alt="Meme Alchemist Logo" className="h-16 mr-4" />
            <h1 className="text-4xl font-bold">Meme History</h1>
          </div>
          <Link to="/" className="text-ai-text hover:text-ai-primary transition duration-300">
            <ArrowLeft size={24} />
            <span className="ml-2">Back to Generator</span>
          </Link>
        </div>
        
        {user ? (
          <div>
            <p className="mb-4">Viewing meme history for: {user.email}</p>
            <p>Your meme history will appear here.</p>
          </div>
        ) : (
          <p>Please log in to view your meme history.</p>
        )}
      </div>
    </div>
  );
};

export default MemeHistory;