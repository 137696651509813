import React, { useState, useEffect } from 'react';
import { Share2 } from 'lucide-react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { User } from 'firebase/auth';

interface MemeIdea {
  memeName: string;
  caption: string;
}

interface GeneratedMeme {
  id: string;
  url: string;
  name: string;
  pageUrl: string;  
}

interface MemesTabProps {
  article: string;
  user: User | null;
}

const MemesTab: React.FC<MemesTabProps> = ({ article, user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [saveError, setSaveError] = useState<string | null>(null);
  const [memeIdeas, setMemeIdeas] = useState<MemeIdea[]>([]);
  const [generatedMemes, setGeneratedMemes] = useState<GeneratedMeme[]>([]);
  const [generatingMemeIndex, setGeneratingMemeIndex] = useState<number | null>(null);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedMeme, setSelectedMeme] = useState<GeneratedMeme | null>(null);

  const N8N_MEME_IDEAS_URL = process.env.REACT_APP_N8N_MEME_IDEAS_URL;
  const N8N_GENERATE_MEME_URL = process.env.REACT_APP_N8N_GENERATE_MEME_URL;
  const N8N_API_KEY = process.env.REACT_APP_N8N_API_KEY;

  const generateMemeIdeas = async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(N8N_MEME_IDEAS_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'promptheader': N8N_API_KEY,
        },
        body: article
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!Array.isArray(data) || data.length === 0 || !data[0].memeIdeas) {
        throw new Error('Invalid response format from n8n workflow');
      }

      const cleanedMemeIdeas = data[0].memeIdeas
        .replace(/```json\n?/g, '')
        .replace(/```\n?/g, '')
        .trim();

      let parsedMemeIdeas;
      try {
        parsedMemeIdeas = JSON.parse(cleanedMemeIdeas);
      } catch (parseError) {
        console.error('Error parsing meme ideas:', parseError);
        throw new Error('Failed to parse meme ideas JSON');
      }

      if (!Array.isArray(parsedMemeIdeas) || parsedMemeIdeas.length !== 9) {
        throw new Error('Invalid meme ideas format');
      }

      setMemeIdeas(parsedMemeIdeas);
      return parsedMemeIdeas;
    } catch (err) {
      console.error('Full error:', err);
      setError(`Error generating meme ideas: ${err instanceof Error ? err.message : String(err)}`);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const saveMemeToFirestore = async (meme: GeneratedMeme) => {
    if (user && user.uid) {
      try {
        const memeData = {
          userId: user.uid,
          userEmail: user.email,
          ...meme,
          createdAt: new Date()
        };
        
        const docRef = await addDoc(collection(db, 'memes'), memeData);
        console.log("Meme saved to history with ID: ", docRef.id);
        console.log("Saved meme data: ", memeData);
        setSaveError(null);
      } catch (error) {
        console.error("Error saving meme to history: ", error);
        setSaveError("Failed to save meme to history. Please try again later.");
      }
    } else {
      console.log("User not logged in or user ID not available, meme not saved to history");
    }
  };

  const generateMeme = async (idea: MemeIdea, index: number) => {
    setGeneratingMemeIndex(index);
    setError('');
    setSaveError(null);

    try {
      const response = await fetch(N8N_GENERATE_MEME_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'promptheader': N8N_API_KEY,
        },
        body: JSON.stringify({
          memeName: idea.memeName,
          caption: idea.caption
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (!Array.isArray(data) || data.length === 0 || !data[0].body || !data[0].body.success) {
        throw new Error('Invalid response format from n8n workflow');
      }

      const memeData = data[0].body.data;

      if (!memeData || !memeData.url || !memeData.page_url) {
        throw new Error('Missing meme data in response');
      }

      const newMeme = {
        id: memeData.page_url.split('/').pop() || '',
        url: memeData.url,
        name: idea.memeName,
        pageUrl: memeData.page_url
      };

      setGeneratedMemes(prevMemes => [...prevMemes, newMeme]);
      
      // Save the meme to Firestore if user is logged in
      if (user) {
        await saveMemeToFirestore(newMeme);
      }

      return newMeme;
    } catch (err) {
      console.error('Full error:', err);
      setError(`Error generating meme: ${err instanceof Error ? err.message : String(err)}`);
      return null;
    } finally {
      setGeneratingMemeIndex(null);
    }
  };

  const handleGenerateClick = async () => {
    setGeneratedMemes([]);
    const ideas = await generateMemeIdeas();
    if (ideas.length === 9) {
      for (let i = 0; i < 9; i++) {
        await generateMeme(ideas[i], i);
      }
    }
  };

  useEffect(() => {
    handleGenerateClick();
  }, []);

  const handleMemeClick = (meme: GeneratedMeme) => {
    window.open(meme.pageUrl, '_blank');
  };

  const handleShareClick = (meme: GeneratedMeme, event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedMeme(meme);
    setShareDialogOpen(true);
  };

  const handleShareConfirm = async () => {
    if (selectedMeme) {
      try {
        const docRef = await addDoc(collection(db, "sharedMemes"), {
          ...selectedMeme,
          likes: 0,
          createdAt: new Date()
        });
        console.log("Meme shared with ID: ", docRef.id);
        alert("Meme shared successfully!");
        window.open(`/shared-meme/${docRef.id}`, '_blank');
      } catch (error) {
        console.error("Error sharing meme: ", error);
        alert("Failed to share meme. Please try again later.");
      }
    }
    setShareDialogOpen(false);
  };

  return (
    <div className="space-y-6">
      <button 
        onClick={handleGenerateClick} 
        disabled={isLoading}
        className={`w-full font-bold py-3 px-4 rounded-lg transition duration-300 generate-button ${
          isLoading ? 'opacity-50 cursor-not-allowed' : ''
        }`}
      >
        {isLoading ? 'Transmuting Ideas...' : 'Generate New Meme Ideas'}
      </button>

      {error && (
        <div className="bg-red-900 bg-opacity-50 border-l-4 border-red-500 text-ai-text p-4 rounded-lg" role="alert">
          <p className="font-bold">Error</p>
          <p>{error}</p>
        </div>
      )}

      {saveError && (
        <div className="bg-red-900 bg-opacity-50 border-l-4 border-red-500 text-ai-text p-4 rounded-lg" role="alert">
          <p className="font-bold">Save Error</p>
          <p>{saveError}</p>
        </div>
      )}

{memeIdeas.length > 0 && (
  <div className="space-y-4">
    <h2 className="text-2xl font-semibold">Meme Formulas</h2>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {memeIdeas.map((idea, index) => (
        <div key={index} className="bg-ai-surface bg-opacity-50 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-2">{idea.memeName}</h3>
          <p className="text-ai-text mb-4">{idea.caption}</p>
        </div>
      ))}
    </div>
  </div>
)}

      {generatedMemes.length > 0 && (
        <div className="space-y-4">
          <h2 className="text-2xl font-semibold">Generated Memes</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {generatedMemes.map((meme) => (
              <div key={meme.id} className="meme-result overflow-hidden relative">
                <img 
                  src={meme.url} 
                  alt={meme.name} 
                  className="w-full h-auto cursor-pointer transition-opacity duration-300 hover:opacity-80" 
                  onClick={() => handleMemeClick(meme)}
                  title="Click to view on Imgflip"
                />
                <div className="p-4">
                  <h3 className="text-lg font-semibold">{meme.name}</h3>
                  <p className="text-sm text-ai-text mt-1">Click to view on Imgflip</p>
                </div>
                <button 
                  className="absolute top-2 right-2 p-2 bg-ai-surface bg-opacity-75 rounded-full hover:bg-opacity-100 transition-colors duration-300"
                  onClick={(e) => handleShareClick(meme, e)}
                  title="Share this meme"
                >
                  <Share2 size={20} className="text-ai-text" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <AlertDialog.Root open={shareDialogOpen} onOpenChange={setShareDialogOpen}>
        <AlertDialog.Portal>
          <AlertDialog.Overlay className="bg-black bg-opacity-50 fixed inset-0" />
          <AlertDialog.Content className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-ai-surface rounded-lg p-6 max-w-md w-full border border-ai-border shadow-lg">
            <AlertDialog.Title className="text-xl font-bold mb-4 text-ai-title">Share Meme</AlertDialog.Title>
            <AlertDialog.Description className="mb-6 text-ai-text">
              This will make the image publicly available on its own URL. Do you want to continue?
            </AlertDialog.Description>
            <div className="flex justify-end space-x-4">
              <AlertDialog.Cancel asChild>
                <button className="px-4 py-2 bg-ai-secondary text-ai-text rounded hover:bg-opacity-80 transition-colors duration-200">
                  Cancel
                </button>
              </AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <button className="px-4 py-2 bg-ai-primary text-white rounded hover:bg-opacity-80 transition-colors duration-200" onClick={handleShareConfirm}>
                  Share
                </button>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  );
};

export default MemesTab;
