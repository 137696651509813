import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './tailwind.output.css';
import MemeGenerator from './MemeGenerator';
import SharedMemePage from './components/SharedMemePage';
import PasswordlessAuth from './components/SignInSignUp';
import MemeHistory from './components/MemeHistory';
import Sidebar from './Sidebar';
import { UserProvider } from './UserContext';
import './futuristic.css';
import { Analytics } from "@vercel/analytics/react";

const AppContent: React.FC = () => {
  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-grow ml-64">
        <div className="app-container">
          <Routes>
            <Route path="/" element={<MemeGenerator />} />
            <Route path="/shared-meme/:id" element={<SharedMemePage />} />
            <Route path="/signin" element={<PasswordlessAuth />} />
            <Route path="/meme-history" element={<MemeHistory />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <UserProvider>
      <Router>
        <AppContent />
        <Analytics />
      </Router>
    </UserProvider>
  </React.StrictMode>
);