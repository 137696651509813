import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc, increment } from 'firebase/firestore';
import { db } from '../firebase';
import { Heart } from 'lucide-react';

interface SharedMeme {
  id: string;
  url: string;
  name: string;
  pageUrl: string;
  likes: number;
}

const SharedMemePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [meme, setMeme] = useState<SharedMeme | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    const fetchMeme = async () => {
      try {
        const docRef = doc(db, "sharedMemes", id!);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const memeData = docSnap.data() as SharedMeme;
          setMeme({ id: docSnap.id, ...memeData, likes: memeData.likes || 0 });
          
          // Check if user has already liked this meme
          const likedMemes = JSON.parse(localStorage.getItem('likedMemes') || '[]');
          setLiked(likedMemes.includes(id));
        } else {
          setError("No such meme!");
        }
      } catch (err) {
        setError("Error fetching meme");
        console.error("Error fetching meme:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchMeme();
  }, [id]);

  const handleLike = async () => {
    if (!meme || liked) return;
  
    try {
      const docRef = doc(db, "sharedMemes", meme.id);
      
      // Log the current meme data
      console.log("Current meme data:", meme);
  
      const updateResult = await updateDoc(docRef, {
        likes: increment(1)
      });
  
      // Log the update result
      console.log("Update result:", updateResult);
  
      setMeme(prevMeme => ({
        ...prevMeme!,
        likes: (prevMeme!.likes || 0) + 1
      }));
      setLiked(true);
  
      // Store liked state in localStorage
      const likedMemes = JSON.parse(localStorage.getItem('likedMemes') || '[]');
      localStorage.setItem('likedMemes', JSON.stringify([...likedMemes, meme.id]));
  
    } catch (err) {
      console.error("Error updating likes:", err);
      if (err instanceof Error) {
        console.error("Error message:", err.message);
        console.error("Error stack:", err.stack);
      }
      // You might want to show an error message to the user here
      alert("Failed to update likes. Please try again later.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!meme) return <div>No meme found</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">{meme.name}</h1>
      <img src={meme.url} alt={meme.name} className="max-w-full h-auto mb-4" />
      <p className="mb-4">
        Original meme:{' '}
        <a 
          href={meme.pageUrl} 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-blue-500 hover:underline"
        >
          View on Imgflip
        </a>
      </p>
      <button 
  className="flex items-center space-x-2 px-4 py-2 rounded-full bg-gray-300 text-gray-500 cursor-not-allowed"
  disabled
>
  <Heart className="w-5 h-5" />
  <span>{meme.likes || 0} Likes</span>
</button>
    </div>
  );
};

export default SharedMemePage;