import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { User } from 'lucide-react';

const PasswordlessAuth: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    // Check if the URL contains a sign-in link
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // If the email is not stored locally, prompt the user for it
        email = window.prompt('Please provide your email for confirmation');
      }
      if (email) {
        signInWithEmailLink(auth, email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');
            navigate('/'); // Redirect to home page after successful sign-in
          })
          .catch((error) => {
            setError(`Error signing in: ${error.message}`);
          });
      }
    }
  }, [auth, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLinkSent(false);

    const actionCodeSettings = {
      url: `${window.location.origin}/signin`, // URL to redirect back to
      handleCodeInApp: true
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setIsLinkSent(true);
    } catch (error) {
      if (error instanceof Error) {
        setError(`Failed to send sign-in link: ${error.message}`);
      } else {
        setError('An unexpected error occurred');
      }
    }
  };

  return (
    <div className="min-h-screen bg-ai-background text-ai-text p-8">
      <div className="max-w-md mx-auto bg-ai-surface rounded-lg p-8">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <img src="/logo.png" alt="Meme Alchemist Logo" className="h-12 mr-4" />
            <h1 className="text-3xl font-bold">Meme Alchemist</h1>
          </div>
          <Link to="/" className="text-ai-text hover:text-ai-primary transition duration-300">
            <User size={24} />
          </Link>
        </div>

        <h2 className="text-2xl font-semibold mb-4">Sign In / Sign Up</h2>

        {isLinkSent ? (
          <div className="text-green-500 mb-4">
            A sign-in link has been sent to your email. Please check your inbox and click the link to sign in.
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block mb-1">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 border rounded bg-ai-background text-ai-text"
                required
              />
            </div>
            {error && <p className="text-red-500">{error}</p>}
            <button type="submit" className="w-full py-2 px-4 bg-ai-primary text-white rounded hover:bg-opacity-90 transition duration-300">
              Send Sign-In Link
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default PasswordlessAuth;