import React, { useState } from 'react';
import MemesTab from './tabs/MemesTab';
import { useUser } from './UserContext';

const MemeGenerator: React.FC = () => {
  const [article, setArticle] = useState("This could be your text. How stupid would it be to manually generate meme ideas if you could get them generated by AI heh?");
  const [activeTab, setActiveTab] = useState('memes');
  const { user } = useUser();

  const renderTab = () => {
    switch (activeTab) {
      case 'memes':
        return <MemesTab article={article} user={user} />;
      default:
        return <MemesTab article={article} user={user} />;
    }
  };

  return (
    <div className="min-h-screen bg-ai-background text-ai-text p-8">
      <div className="max-w-4xl mx-auto meme-generator-container rounded-lg p-8">
        <h1 className="text-4xl font-bold mb-6">Meme Generator</h1>
        
        <textarea
          placeholder="Paste your article here..."
          value={article}
          onChange={(e) => setArticle(e.target.value)}
          className="w-full h-40 p-2 border-2 rounded-lg mb-4 bg-opacity-50 focus:border-ai-primary focus:shadow-ai-glow transition duration-300"
        />
        
        <nav className="flex border-b border-ai-secondary mb-4">
          <button 
            onClick={() => setActiveTab('memes')}
            className={`py-2 px-4 font-semibold ${
              activeTab === 'memes'
                ? 'text-ai-primary border-b-2 border-ai-primary' 
                : 'text-ai-text hover:text-ai-secondary'
            } transition duration-300`}
          >
            Memes
          </button>
          <span className="py-2 px-4 font-semibold text-gray-500 cursor-not-allowed">Picture</span>
          <span className="py-2 px-4 font-semibold text-gray-500 cursor-not-allowed">Titles</span>
          <span className="py-2 px-4 font-semibold text-gray-500 cursor-not-allowed">Critique</span>
        </nav>

        {renderTab()}
      </div>
    </div>
  );
};

export default MemeGenerator;