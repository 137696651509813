import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { User, LogOut, History } from 'lucide-react';
import { useUser } from './UserContext';
import { getAuth, signOut } from 'firebase/auth';

const Sidebar: React.FC = () => {
  const { user } = useUser();
  const location = useLocation();
  const auth = getAuth();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div className="w-64 bg-ai-surface h-screen fixed left-0 top-0 p-6 flex flex-col">
      <img src="/logo.png" alt="Meme Alchemist Logo" className="h-16 mb-8" />
      <div className="flex-grow">
        {user ? (
          <>
            <p className="text-ai-text mb-4">Logged in as: {user.email}</p>
            <Link 
              to="/meme-history" 
              className={`flex items-center mb-4 text-ai-text hover:text-ai-primary transition duration-300 ${location.pathname === '/meme-history' ? 'text-ai-primary' : ''}`}
            >
              <History size={24} />
              <span className="ml-2">View Meme History</span>
            </Link>
            <button 
              onClick={handleSignOut} 
              className="flex items-center text-ai-text hover:text-ai-primary transition duration-300"
            >
              <LogOut size={24} />
              <span className="ml-2">Sign Out</span>
            </button>
          </>
        ) : (
          <Link 
            to="/signin" 
            className={`flex items-center text-ai-text hover:text-ai-primary transition duration-300 ${location.pathname === '/signin' ? 'text-ai-primary' : ''}`}
          >
            <User size={24} />
            <span className="ml-2">Sign In / Sign Up</span>
          </Link>
        )}
      </div>
      <div className="mt-auto">
        <Link 
          to="/" 
          className={`flex items-center text-ai-text hover:text-ai-primary transition duration-300 ${location.pathname === '/' ? 'text-ai-primary' : ''}`}
        >
          <span>Meme Generator</span>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;